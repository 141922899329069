.btn-primary {
  font-family: $primary-font;
  font-size:20px;
  padding:6px 15px;
  display:inline-block;
  text-align:center;
  border-radius:25px;
  border:0;
  text-transform:uppercase;
  i {
    font-size:18px;
    margin-left:4px;
  }
  &.white {
    background:white;
    color:black;
    &:hover,
    &:focus {
      background:$primary-color;
    }
  }
  &.focus,
  &:focus {
    box-shadow:0 0 0 0 rgba(0,123,255,0);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background:darken($primary-color, 10%);
  }
}

.btn-line {
  @include btn_line();
  &:before {
    background:$primary-color;
  }
  &:after {
    background:black;
  }
}

.btn-back {
  border:2px solid white;
  border-radius:25px;
  padding:4px 15px;
  text-transform:uppercase;
  margin-top:20px;
  display:inline-block;
  &:hover,
  &:focus {
    text-decoration: none;
    border-color:$primary-color;
    color:$primary-color;
  }
}

.back {
  position:fixed;
  bottom:3rem;
  top:auto;
  left:2rem;
  font-size:0;
  width:50px;
  height:50px;
  border-radius:25px;
  background:white;
  z-index:99;

  font-family: $primary-font;
  color:black;
  text-transform:uppercase;
  opacity:.75;
  i {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);

    margin-right:4px;
    font-size:20px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color:black;
    opacity:1;
  }
}

@media (min-width: 992px) {
  .back {
    font-size:16px;
    position:absolute;
    top:-40px;
    left:0;
    width:auto;
    height:auto;
    background:transparent;
    border-radius:0;
    color:white;
    opacity:.5;
    transition:opacity ease-in-out .3s;
    i {
      font-size:14px;
      position:relative;
      top:auto;
      left:auto;
      transform:translate(0,0);
    }
    &:hover,
    &:focus {
      color:white;
      opacity:1;
    }
  }
  .btn-line {
    &:after {
      transition:width ease-in-out .25s;
    }
  }
  .btn-back {
    transition:border ease-in-out .25s, color ease-in-out .25s;
  }
}