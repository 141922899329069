.beer-item {
  display:block;
  width:100%;
  height:450px;
  position:relative;
  background:black;
  margin-top:100px;
  text-decoration:none;
  border:4px solid white;
  .organisation-label {
    width:100%;
    height:180px;
    display:block;
    position:absolute;
    margin-top:15px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    text-decoration:none;
    z-index:100;
  }
  .beer-label {
    width:96%;
    height:180px;
    background:rgba(0,0,0,.85);
    display:block;
    position:absolute;
    margin-top:15px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index:100;
    border:1px solid rgba(104, 141, 103, .15);
    border-bottom-left-radius: 25%;
    border-bottom-right-radius: 25%;
    text-decoration:none;
    .teaser {
      @include font_P1();
      font-size:17px;
      text-align:center;
      text-transform:uppercase;
      text-decoration:none;
      word-spacing: 9999999px;
      line-height:20px;
      color:var(--main-color, $green);
      display:flex;
      align-items: center;
      justify-content: center;
      margin:0;
      padding:0;
      position:absolute;
      top:33px;
      width:60px;
      height:60px;
      border:2px solid rgba(255,255,255,.5);
      z-index:3;

      .macht {
        font-size:17px;
        position:absolute;
        right:-20px;
        top:-20px;
        color:var(--main-color, $green);
      }

      &:before,
      &:after {
        content:"";
        position:absolute;
        left:0;
        right:0;
        margin:0 auto;
        background:black;
        width:30px;
        height:2px;
        z-index:4;
      }
      &:before {
        top:-2px;
      }
      &:after {
        bottom:-2px;
      }
    }
    .teaser-left {
      left:12px;
    }
    .teaser-right {
      right:12px;
    }
    .beer-logo-small,
    .beer-logo-large {
      position:absolute;
      left:0;
      right:0;
      margin:0 auto;
    }
    .beer-logo-small {
      top:9px;
      width:42px;

      z-index:8;
      .fill-white {
        fill:white;
      }
    }
    .beer-logo-large {
      top:4px;
      width:63px;
      z-index:6;
      .icon-svg {
        .fill-white {
          fill:var(--main-color, $green);
          opacity:.3;
        }
      }
      svg {
        max-width:100%;
        height:100%;
      }
    }
    .title {
      display:flex;
      justify-content: center;
      align-items: center;
      width:100%;
      height:50px;
      overflow:hidden;
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      padding:10px;
      text-align:center;
      z-index:5;
      text-decoration:none;
      background:var(--main-color, $green);
      h2 {
        @include font_H4();
        font-size:18px;
        font-weight:700;
        line-height:1;
        text-transform:uppercase;
        margin:0;
        padding:0;
        text-decoration:none;
      }
    }
  }
  .image {
    position:relative;
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index:5;
    img {
      position:absolute;
      top: -70px;
      left:0;
      right:0;
      margin:0 auto;
      max-width:100%;
      max-height:460px;
      opacity:1;
      transition:all ease-in-out .25s;
      text-decoration:none;
    }
  }
  .smoke {
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    background:black url('/images/bg-smoke.png') no-repeat center center fixed;
    background-size: cover;
    z-index:0;
    &:before {
      content: '';
      background-color: var(--main-color, $green);
      display: block;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
      opacity:.8;
    }
  }
  &:before,
  &:after  {
    content:"";
    background:var(--main-color, $green);
    position:absolute;
    z-index:10;
  }
  &:before {
    right:-4px;
    top:-3px;
    width:4px;
    height:0;
    transition:height ease-in-out .25s;
  }
  &:after {
    bottom:-4px;
    left:-3px;
    width:0;
    height:4px;
    transition:width ease-in-out .25s;
  }
  &:hover,
  &:focus {
    .image {
      img {
        max-height:500px;
        top:-100px;
        opacity:1;
      }
    }
    &:before {
      height:calc(100% + 7px);
    }
    &:after {
      width:calc(100% + 7px);
    }
  }
}

@media (min-width: 768px) {}
@media (min-width: 992px) {
  .beer-item {
    .beer-label {
      width:85%;
      .title {
        h2 {
          font-size:22px;
        }
      }
      .teaser-left {
        left:27px;
      }
      .teaser-right {
        right:27px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .beer-item {
    height:336px;
    .beer-label {
      .title {
        h2 {
          font-size:24px;
        }
      }
    }
    .image {
      img {
        top:-50px;
        max-height:340px;
      }
    }
    &:hover,
    &:focus {
      .image {
        img {
          max-height: 370px;
          top: -70px;
          opacity: 1;
        }
      }
    }
  }
}
@media (min-width: 1600px) {
  .beer-item {
    height:450px;
    .image {
      img {
        top:-70px;
        max-height:460px;
      }
    }
    &:hover,
    &:focus {
      .image {
        img {
          max-height: 500px;
          top: -100px;
          opacity: 1;
        }
      }
    }
  }
}