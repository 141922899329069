.slider {
  width: 100%;
  position: relative;
  min-height: 275px;
  height: 40vh;
  display: flex;
  flex-grow: 1;
  z-index: 0;

  .swiper-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .swiper-slide {
      text-align: center;
      background: black;

      .image {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;

        .title {
          width: 100%;
          height: auto;
          position: absolute;
          right: 0;
          padding: 0 15px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;

          * {
            position: relative;
            text-align: center;
            color: white;
          }

          h1 {
            @include font_H1();
            font-size:32px;
            line-height:1.1;
            margin-bottom: 25px;
          }

          p, a {
            font-family: $primary-font;
            font-size: 17px;
            text-decoration: none;
          }

          .btn-line {
            &:after {
              background: white;
            }
          }
        }
        &:after {
          content:"";
          background:rgba(0,0,0,0.6);
          width:100%;
          height:100%;
          z-index:4;
          position:absolute;
          top:0;
          left:0;
        }
      }
    }

    .swiper-pagination {
      bottom: 15px;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background: white;
      opacity: 1;
      border: 3px solid white;
    }

    .swiper-pagination-bullet-active {
      background: $primary-color;
      border: 3px solid white;
    }
  }

  #trigger {
    position: absolute;
    width: 1px;
    height: 1px;
    top:0;
    transform: translateY(0);
  }
}
@media (min-width: 768px) {
  .slider {
    min-height: 450px;
    height: 70vh;
  }
}
@media (min-width: 992px) {
  .slider {
    min-height: 450px;
    height: 100vh;

    .swiper-container {
      .swiper-slide {
        .image {
          .title {
            width: 55%;
            padding: 0 50px;

            * {
              text-align: left;
            }
            h1 {
              font-size:36px;
              line-height:1.2;
            }
            p, a {
              font-size: 20px;
            }
          }
          &:before,
          &:after {
            content: "";
            width: 100%;
            left: 0;
            right: 0;
            display: block;
            position: absolute;
            z-index: 1;
          }

          &:before {
            @include gradient_bottom();
            height: 50%;
          }

          &:after {
            @include gradient_top();
            height: 50%;
            opacity: .65;
          }
        }
      }
      .swiper-pagination {
        bottom: 30px;
      }
    }
    #trigger {
      top: 80%;
      transform: translateY(-80%);
    }
  }
}

@media (min-width: 1200px) {
  .slider {
    min-height: 600px;
    height: 100vh;

    .swiper-container {
      .swiper-slide {
        .image {
          .title {
            width: 55%;
            padding: 0 50px;
            h1 {
              font-size:58px;
              line-height:1.2;
            }
            p, a {
              font-size: 22px;
            }
          }
        }
      }

      .swiper-pagination-bullet {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (min-width: 1600px) {
  .slider {
    min-height: 767px;
    height: 100vh;

    .swiper-container {
      .swiper-slide {
        .image {
          .title {
            width: 55%;
            padding: 0 100px;

            h1 {
              font-size:72px;
              line-height:1.2;
            }

            p, a {
              font-size: 24px;
            }
          }
        }
      }

      .swiper-pagination-bullet {
        width: 22px;
        height: 22px;
      }
    }
  }
}