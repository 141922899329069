.intro-wrapper {
  .wrapper {
    .forms {
      padding:0;
      margin:25px 0 0 0;
      h2 {
        margin:0 0 25px 0;
      }
    }
  }
}

.form-control {
  @include font_P1();
  background:white;
  border-radius:4px;
  position:relative;
  width:100%;
  height:54px;
  border:1px solid #222;
  color:#000;
  transition:border-color ease-in-out .08s;
  z-index:5;
  &:focus {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    border:2px solid $green;
  }
}
textarea {
  &.form-control {
    height:195px;
    resize:none;
  }
}

.alert-danger {
  background:$red;
  border:1px solid darken($red, 10%);
  color:white;
  ul {
    margin:0;
    padding:0 0 0 20px;
    li {
      color:white;
    }
  }
}

.alert-success {
  @include font_P1();
  padding:.75rem .75rem .75rem 20px;
  background:$green;
  border:1px solid darken($green, 10%);
  color:white;
}

.form-group {
  &.has-error {
    position:relative;
    .form-control {
      position:relative;
      background:lighten($red, 66%);
      border:2px solid $red;
    }
    &:after {
      content:"\f06a";
      font-family: "Font Awesome 5 Free";
      font-weight:700;
      color:$red;
      position:absolute;
      right:15px;
      top:50%;
      transform:translateY(-50%);
      z-index:5;
    }
  }
}

.form-control::-webkit-input-placeholder {
  @include font_P1();
  color: #666;
}
.form-control:focus::-webkit-input-placeholder {
  @include font_P1();
  color: #222;
}
.form-control:-moz-placeholder {
  @include font_P1();
  color: #666;
}
.form-control:focus:-moz-placeholder {
  @include font_P1();
  color: #222;
}
.form-control::-moz-placeholder {
  @include font_P1();
  color: #666;
}
.form-control:focus::-moz-placeholder {
  @include font_P1();
  color: #222;
}
.form-control:-ms-input-placeholder {
  @include font_P1();
  color: #666;
}
.form-control:focus:-ms-input-placeholder {
  @include font_P1();
  color: #222;
}

@media (min-width: 992px) {
  .intro-wrapper {
    .wrapper {
      .forms {
        background:rgba(255,255,255,.08);
        margin:0;
        padding:20px 30px 30px 30px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .intro-wrapper {
    .wrapper {
      .forms {
        padding:40px 50px 50px 50px;
      }
    }
  }
}