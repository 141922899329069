.news-overview {
  position:relative;
  display:block;
  width:100%;
  margin-top:30px;
}
.news-list {
  @include list_default();
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  li {
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;

    &:first-child {
      padding:0;
      margin:0;
      opacity: 0;
      animation: titleFadeInUp .4s forwards ease;
      animation-delay: .3s;
      p {
        font-size:22px;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4){
      opacity: 0;
      animation: titleFadeInUp .4s forwards ease;
    }
    &:nth-child(2) {
      animation-delay: .5s;
    }
    &:nth-child(3) {
      animation-delay: .8s;
    }
    &:nth-child(4) {
      animation-delay: 1s;
    }

    a {
      display: block;
      width: 100%;
      height: auto;
      position: relative;

      span.title {
        position: absolute;
        bottom: 30px;
        left: 30px;
        z-index: 5;
        display: flex;
        align-items: center;

        h2 {
          @include font_H3();
          margin: 0;
          color: white;
        }

        span.badge {
          font-family: $primary-font;
          font-size:18px;
          text-transform:uppercase;
          font-weight:700;
          margin-left: 15px;
          margin-right:15px;
          text-transform: uppercase;
          position: relative;
        }
      }

      span.image {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        z-index: 1;

        img {
          max-width: 100%;
          filter: grayscale(100%);
        }
        &:before {
          content:"";
          @include gradient_bottom();
          position:absolute;
          width:100%;
          height:250px;
          opacity:.85;
          z-index:2;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          mix-blend-mode: multiply;
          transition: opacity 0.3s ease-in-out;
        }
      }
      &:hover,
      &:focus {
        span.image {
          img {
            transition: transform 5s linear;
            transform: rotate(-2deg) scale(1.2);
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &.red {
      a {
        span.title {
          span.badge {
            background: $red !important;
          }
        }
        span.image {
          &:after {
            background: $red;
            background: linear-gradient(110deg, $red 15%, #b91818 90%);
          }
        }
      }
    }
    &.green {
      a {
        span.title {
          span.badge {
            background: $green !important;
          }
        }
        span.image {
          &:after {
            background: $green;
            background: linear-gradient(110deg, $green 15%, #427f40 90%);
          }
        }
      }
    }
    &.orange {
      a {
        span.title {
          span.badge {
            background: $orange !important;
          }
        }
        span.image {
          &:after {
            background: $orange;
            background: linear-gradient(110deg, $orange 15%, #c78319 90%);
          }
        }
      }
    }
    &.mint {
      a {
        span.title {
          span.badge {
            background: $mint !important;
          }
        }
        span.image {
          &:after {
            background: $mint;
            background: linear-gradient(110deg, $mint 15%, #17908f 90%);
          }
        }
      }
    }
    &.taupe {
      a {
        span.title {
          span.badge {
            background: $taupe !important;
          }
        }
        span.image {
          &:after {
            background: $taupe;
            background: linear-gradient(110deg, $taupe 15%, #baa814 90%);
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .news-overview {
    margin-top:200px;
  }
  .news-list {
    flex-direction: row;
    li {
      width: 50%;
      padding: 0 15px;
      a {
        span.title {
          span.badge {
            margin-right:15px;
          }
        }
      }
      &:first-child {
        margin:30px 0 0 0;
        padding:0 50px;
      }
    }
  }
}
@media (min-width: 1200px) {}
@media (min-width: 1600px) {}