html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100% !important;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: $primary-font;
  color: $primary-font-color;
  padding:83px 0 0 0;
  margin: 0;
  background-color:#111;
  background-image:none;
}

::-moz-selection {
  background: $primary-color;
  color: white;
}

::selection {
  background: $primary-color;
  color: white;
}

.container {
  padding-left:30px;
  padding-right:30px;
}

// FONTS
h1 {
  @include font_H1();
}
h2 {
  @include font_H2();
}
p, ul, ol, li, a, table, tr, td {
  @include font_P1();
}
p {
  &.large {
    a {
      font-size:30px;
    }
  }
}
a {
  outline:0;
  &:hover,
  &:focus {
    color:$primary-color;
  }
}

.content {
  padding:25px 0;
  h1, h2 {
    @include font_H2();
    margin:0;
  }
  h3 {
    @include font_H3();
  }
  p, ul, ol, table, img {
    margin:0 0 35px 0;
  }
  img {
    max-width:100%;
    height:auto !important;
  }
}

.full-content {
  width:100%;
  position:relative;
  margin-top:30px;
  .text-block {
    text-align:left;
    max-width:100%;
    margin:0 auto;
    overflow:hidden;
    opacity: 0;
    animation: titleFadeInUp .5s forwards ease;
    animation-delay: .35s;
    a, li, ol, p, table, td, tr, ul {
      @include font_P1();
    }
  }
  .images {
    margin:20px 0;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    opacity: 0;
    animation: titleFadeInUp .6s forwards ease;
    animation-delay: .7s;
  }
}

// LOGO

.logo-svg,
.icon-svg {
  .fill {
    fill-rule:evenodd;
    clip-rule:evenodd;
  }
  .fill-black {
    fill:black;
  }
  .fill-white {
    fill:white;
  }
}


// PADDING
.pt-lg {
  padding-top:30px;
}
.pb-lg {
  padding-bottom:30px;
}

.pt-md {
  padding-top:15px;
}
.pb-md {
  padding-bottom:15px;
}

// TEXT-BLOCK
.text-block {
  h2 {
    margin:0 0 15px 0;
    strong {
      color:$primary-color;
    }
  }
  a {
    @include btn_line();
    &:before {
      background:$primary-color;
    }
    &:after {
      background:white;
    }
    &:hover,
    &:focus {
      color:white;
    }
  }
  p, ul, ol, table {
    margin:0 0 30px 0;
  }
}

// WRAPPER DETAIL PAGE

.intro-wrapper {
  margin-top: 30px;
  position: relative;
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items:flex-start;
    align-content:flex-start;
    position:relative;
    .list-details,
    .short {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      opacity: 0;
      animation: titleFadeInUp .4s forwards ease;
      animation-delay: .7s;
    }
    .list-details {
      order:2;
      margin-top:20px;
      h1 {
        color:var(--main-color, $green);
      }
    }
    .images,
    .forms {
      width: 100%;
      max-width: 100%;
      height:auto;
      position: relative;
      flex: 0 1 auto;
      border-radius: 4px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;
      opacity: 0;
      animation: titleFadeInUp .5s forwards ease;
      animation-delay: .2s;
    }
    .images {
      order:1;
    }
    &.green {
      h1 {
        color: $green;
      }
    }
    &.red {
      h1 {
        color: $red;
      }
    }
    &.orange {
      h1 {
        color: $orange;
      }
    }
    &.taupe {
      h1 {
        color: $taupe;
      }
    }
    &.mint {
      h1 {
        color: $mint;
      }
    }
  }
}

// TRIGGER

#trigger {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  body {
    padding:0;
    background-color:black;
    background-image:url('/images/bg.jpg');
    background-repeat:no-repeat;
    background-position:top center;
    background-size:100%;
    background-attachment: fixed;
  }

  .container {
    padding-left:15px;
    padding-right:15px;
  }

  p {
    margin-bottom:1.7rem;
    &.large {
      a {
        font-size:24px;
      }
    }
  }

  .text-block {
    h2 {
      margin:0 0 8px 0;
    }
    a {
      &:after {
        transition:width ease-in-out .25s;
      }
    }
  }

  .content {
    padding:50px 0;
  }
  .full-content {
    margin-top:200px;
    .images {
      max-width:960px;
      margin:40px auto;
    }
    .text-block {
       max-width: 960px;
    }
  }

  .intro-wrapper {
    margin-top:200px;
    .wrapper {
      flex-direction: row;
      .list-details,
      .short {
        width:50%;
        padding-right: 20px;
        animation-delay: .3s;
      }
      .list-details {
        order:1;
        margin-top:0px;
      }
      .images,
      .forms {
        width: 50%;
        animation-delay: .7s;
      }
      .images {
        order:2;
      }
    }
  }

  // PADDING
  .pt-lg {
    padding-top:40px;
  }
  .pb-lg {
    padding-bottom:40px;
  }

  .pt-md {
    padding-top:20px;
  }
  .pb-md {
    padding-bottom:20px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width:calc(100% - 75px);
  }
  p {
    &.large {
      a {
        font-size:30px;
      }
    }
  }
  .text-block {
    h2 {
      margin:0 0 10px 0;
    }
  }
  .full-content {
    .images {
      max-width:1100px;
      margin:40px auto;
    }
    .text-block {
      max-width: 1100px;
    }
  }

  // PADDING
  .pt-lg {
    padding-top:60px;
  }
  .pb-lg {
    padding-bottom:60px;
  }

  .pt-md {
    padding-top:30px;
  }
  .pb-md {
    padding-bottom:30px;
  }

  .intro-wrapper {
    .wrapper {
      .list-details,
      .short {
        width:40%;
        padding-right: 60px;
      }
      .images,
      .forms {
        width: 60%;
      }
    }
  }
}
@media (min-width: 1500px) {
  .full-content {
    .images {
      max-width:1350px;
      margin:40px auto;
    }
  }
}
@media (min-width: 1600px) {
  .container {
    max-width:1540px;
  }
  .text-block {
    h2 {
      margin:0 0 12px 0;
    }
  }

  // PADDING
  .pt-lg {
    padding-top:80px;
  }
  .pb-lg {
    padding-bottom:80px;
  }

  .pt-md {
    padding-top:40px;
  }
  .pb-md {
    padding-bottom:40px;
  }
}
