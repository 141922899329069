.brewery-intro {
    width:100%;
    position:relative;
    margin-top:30px;
  .text-block {
    text-align:left;
    max-width:100%;
    margin:0 auto;
    overflow:hidden;
    opacity: 0;
    animation: titleFadeInUp .5s forwards ease;
    animation-delay: .35s;
    a, li, ol, p, table, td, tr, ul {
      @include font_P1();
    }
  }
  .images {
    margin:20px 0;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    opacity: 0;
    animation: titleFadeInUp .6s forwards ease;
    animation-delay: .7s;
  }
}

.content-block {
  width:100%;
  display:block;
  position:relative;
  .text-block {
    max-width:100%;
    margin:20px auto;
    position:relative;
    display:flex;
    flex-direction: column;
    .title {
      flex:100%;
      opacity: 0;
      animation: titleFadeInUp .6s forwards ease;
      animation-delay: .9s;
      h2 {
        @include font_H1();
        text-transform:uppercase;
        line-height:1;
        span {
          display:block;
          text-align:left;
        }
        span + span {
          color:$primary-color;
          margin-left:0;
        }
      }
    }
    .text {
      flex:100%;
      opacity: 0;
      animation: titleFadeInUp .6s forwards ease;
      animation-delay: 1.1s;
      h3 {
        margin:20px 0 4px 0;
      }
    }
  }
}
@media (min-width: 768px) {
  .content-block {
    .text-block {
      .title {
        h2 {
          span {
            display:inline-block;
            text-align:center;
          }
          span + span {
            margin-left:8px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .brewery-intro {
    margin-top:200px;
    .text-block {
      max-width: 960px;
      text-align:center;
    }
    .images {
      margin:40px auto;
      max-width:960px;
    }
  }
  .content-block {
    .text-block {
      max-width: 960px;
      margin:50px auto;
      flex-direction: row;
      .title {
        flex:33.33333%;
        h2 {
          span {
            display:block;
            text-align:right;
          }
          span + span {
            margin-left:0;
          }
        }
      }
      .text {
        flex:66.666667%;
        padding-left:50px;
        h3 {
          margin:0;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .brewery-intro {
    .images {
      max-width:1100px;
      margin:40px auto;
    }
    .text-block {
      max-width: 1100px;
    }
  }
  .content-block {
    .images {
      max-width:1100px;
      margin:40px auto;
    }
    .text-block {
      max-width: 1100px;
    }
  }
}
@media (min-width: 1500px) {
  .brewery-intro {
    .images {
      max-width: 1350px;
      margin: 40px auto;
    }
  }
  .content-block {
    .images {
      max-width: 1350px;
      margin: 40px auto;
    }
  }
}