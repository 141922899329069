.navbar {
  border-bottom:1px solid rgba(255,255,255,.1);
  padding:.5rem 30px;
  .navbar-toggler {
    width:48px;
    height:35px;
    padding:0;
    position:relative;
    overflow:hidden;
    transition:all ease-in-out .25s;
    z-index:1002;
    span {
      position:absolute;
      background:white;
      border-radius:3px;
      height:3px;
      left:0;
      &:nth-child(1) {
        top:0;
        width:30px;
      }
      &:nth-child(2) {
        top:15px;
        width:48px;
      }
      &:nth-child(3) {
        bottom:0px;
        width:40px;
      }
    }
    &.open {
      background:rgba(255,255,255,.25);
      width:31px;
      height:31px;
      margin-right:5px;
      border-radius:50%;
      span {
        transition:all ease-in-out .25s;
        &:nth-child(1),
        &:nth-child(2){
          width:3px;
          height:19px;
          border-radius:2px;
          animation: fadeOutUp 0s linear infinite 0s;
        }
        &:nth-child(1){
          left:13px;
          top:5px;
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
        &:nth-child(2){
          opacity:1;
          left:13px;
          top:5px;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
        &:nth-child(3) {
          display:none;
        }
      }
    }
  }
  .logo {
    max-width:90px;
    margin:10px 0 10px 0px;
    z-index:1005;
    img {
      max-width:100%;
      height:auto !important;
    }
  }
  .menu {
    z-index:5;
    .nav {
      li {
        a {
          font-family: $primary-font;
          font-size:25px;
          font-weight:400;
          line-height:1;
          border-radius:25px;
          color:white;
          margin:0 12px;
          padding:7px 15px 9px 15px;
          text-transform:lowercase;
          text-decoration:none;
          &:hover {
            background:white;
            color:black;
          }
          &:focus {
            background:lighten(white, 25%);
            color:black;
          }
        }
        &.active {
          a {
            background:white;
            color:black;
          }
        }
      }
    }
  }
  &.smaller {
    background:rgba(0,0,0,0.9);
    .logo {
      max-width:90px;
    }
  }
}
.language-select {
  position:absolute;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  right:105px;
  top:23px;
  width:92px;
  height:36px;
  background:rgba(255,255,255,.1);
  border:2px solid transparent;
  border-radius:18px;
  a {
    font-size:17px;
    width:44px;
    height:32px;
    line-height:32px;
    vertical-align: middle;
    display:block;
    border-radius:15px;
    text-align:center;
    &.active {
      background:rgba(255,255,255,.3);
      color:white;
      text-decoration: none;
    }
    &:hover,
    &:focus {
      background:rgba(255,255,255,.3);
      color:white;
      text-decoration: none;
    }
  }
}
@media (max-width: 991px) {
  .navbar {
    .navbar-nav {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
    .menu {
      position:fixed;
      background:rgba(0,0,0,1);
      width:100%;
      height:83px;
      z-index:1001;
      opacity:1;
      top:-100%;
      .nav {
        li {
          a {
            margin:5px 12px;
            display:inline-block;
          }
        }
      }
    }
    .m-close {
      top:-100%;
      left:0;
      height:auto;
      visibility: hidden;
    }
    .m-open {
      top:0;
      left:0;
      height:100%;
      visibility: visible;
      animation-name: slideInDown;
      animation-duration: .5s;
      animation-fill-mode: both;
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    padding:.5rem 1rem;
    transition:all ease-in-out .3s;
    border-bottom:1px solid rgba(255,255,255,0);
    .navbar-toggler {
      display:none;
    }
    .logo {
      max-width:140px;
      transition:all ease-in-out .3s;
      margin:20px 0 20px 20px;
    }
    .menu {
      .nav {
        li {
          a {
            font-size:18px;
            margin:0 8px;
            transition:background ease-in-out .3s;
          }
        }
      }
    }
    .m-close,
    .m-open{
      display:flex;
    }
    &.smaller {
      border-bottom:1px solid rgba(255,255,255,.075);
      .logo {
        max-width:100px;
      }
    }
  }
  .language-select {
    right:43px;
    top:8px;
    width:72px;
    height:28px;
    font-size:14px;
    border-radius:15px;
    a {
      font-size:14px;
      width:34px;
      height:24px;
      line-height:24px;
      transition:background ease-in-out .3s;
    }
  }
}
@media (min-width: 1200px) {
  .navbar {
    .logo {
      max-width:160px;
    }
    .menu {
      .nav {
        li {
          a {
            font-size:20px;
            margin: 0 12px;
          }
        }
      }
    }
    &.smaller {
      .logo {
        max-width:110px;
      }
    }
  }
}
@media (min-width: 1600px) {
  .navbar {
    .logo {
      max-width: 200px;
    }
    &.smaller {
      .logo {
        max-width:140px;
      }
    }
  }
}