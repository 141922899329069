.brewers {
  margin-top: -25px;
}

.team-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    width: 100%;
    padding:0 10px;
    position: relative;
    cursor:pointer;
    border-radius:5px;
    overflow:hidden;

    .team-item {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 30px;
      position: relative;
      text-decoration: none;
      border-radius:5px;
      cursor:pointer;

      .description {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 2;
        opacity: 1;
        .inner {
          width: 100%;
          padding: 10px;
          position: absolute;
          top: 85%;
          left: 50%;
          transform: translate(-50%, -85%);
          z-index: 4;
          h3 {
            @include font_H4();
            text-transform:uppercase;
            line-height:1.5;
            letter-spacing:1px;
            margin:0;
            padding:0;
            display:block;
          }
          .btn-mail {
            display:inline-block;
            font-size:15px;
            font-weight:700;
            text-transform:uppercase;
            background:white;
            border-radius:25px;
            padding:3px 12px;
            transition:color ease-in-out .3s, background ease-in-out .3s;
            i {
              margin-right:4px;
              font-size:15px;
            }
            &:hover,
            &:focus {
              color:white;
              text-decoration: none;
            }
          }
        }
      }
      .image {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        border-radius:5px;
        z-index:1;
        img {
          border-radius:5px;
          max-width:100%;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: $green;
          background: linear-gradient(110deg, $green 15%, #427f40 90%);
          mix-blend-mode: multiply;
          transition: opacity 0.3s ease-in-out;
        }
      }

      &:hover,
      &:focus {
        .description {
          opacity:1;
          .inner {
            h3 {
              display:block;
              animation-duration: .5s;
              animation-fill-mode: both;
              animation-name: titleFadeInUp;
              animation-delay: .2s;
            }
            .btn-mail {
              display:inline-block;
              animation-duration: .5s;
              animation-fill-mode: both;
              animation-name: titleFadeInUp;
              animation-delay: .35s;
            }
          }
        }
        .image {
          img {
            filter: grayscale(100%);
            transition: transform 5s linear;
            transform: rotate(-2deg) scale(1.2);
          }
          &:after {
            opacity: 1;
          }
        }
      }
      &.red {
        .description {
          .inner {
            .btn-mail {
              color:$red;
              &:hover,
              &:focus {
                background:$red;
                color:white;
              }
            }
          }
        }

        .image {
          &:after {
            background: $red;
            background: linear-gradient(110deg, $red 15%, #b91818 90%);
          }
        }
      }
      &.taupe {
        .description {
          .inner {
            .btn-mail {
              color:$taupe;
              &:hover,
              &:focus {
                background:$taupe;
                color:white;
              }
            }
          }
        }
        .image {
          &:after {
            background: $taupe;
            background: linear-gradient(110deg, $taupe 15%, #baa814 90%);
          }
        }
      }
      &.orange {
        .description {
          .inner {
            .btn-mail {
              color:$orange;
              &:hover,
              &:focus {
                background:$orange;
                color:white;
              }
            }
          }
        }
        .image {
          &:after {
            background: $orange;
            background: linear-gradient(110deg, $orange 15%, #c78319 90%);
          }
        }
      }
      &.green {
        .description {
          .inner {
            .btn-mail {
              color:$green;
              &:hover,
              &:focus {
                background:$green;
                color:white;
              }
            }
          }
        }
        .image {
          &:after {
            background: $green;
            background: linear-gradient(110deg, $green 15%, #427f40 90%);
          }
        }
      }
      &.mint {
        .description {
          .inner {
            .btn-mail {
              color:$mint;
              &:hover,
              &:focus {
                background:$mint;
                color:white;
              }
            }
          }
        }
        .image {
          &:after {
            background: $mint;
            background: linear-gradient(110deg, $mint 15%, #17908f 90%);
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .brewers {
    margin-top:0;
  }
  .team-list {
    li {
      width:33.333333%;
      .team-item {
        .description {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          z-index: 2;
          opacity: 0;
          transition: all ease-in-out .3s;
          .inner {
            width: 100%;
            padding: 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
            h3 {
              @include font_H4();
              text-transform:uppercase;
              line-height:1.5;
              letter-spacing:1px;
              margin:0;
              padding:0;
              display:none;
            }
            .btn-mail {
              display:none;
              font-size:15px;
              font-weight:700;
              text-transform:uppercase;
              background:white;
              border-radius:25px;
              padding:3px 12px;
              transition:color ease-in-out .3s, background ease-in-out .3s;
              i {
                margin-right:4px;
                font-size:15px;
              }
              &:hover,
              &:focus {
                color:white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .team-list {
    li {
      width:20%;
    }
  }
}
@media (min-width: 1600px) {

}
