// FONTS

@mixin font_H1 {
  font-family: $secondary-font;
  font-size:48px;
  font-weight:900;
  line-height:1.2;
  color:white;
  transition:font-size ease-in-out .4s;
  @media (min-width: 992px) {
    font-size:44px;
  }
  @media (min-width: 1200px) {
    font-size:58px;
  }
  @media (min-width: 1600px) {
    font-size:70px;
  }
}

@mixin font_H2 {
  font-family: $secondary-font;
  font-size:32px;
  font-weight:900;
  line-height:1.2;
  color:white;
  @media (min-width: 992px) {
    font-size:32px;
  }
  @media (min-width: 1200px) {
    font-size:38px;
  }
  @media (min-width: 1600px) {
    font-size:44px;
  }
}
@mixin font_H3 {
  font-family: $secondary-font;
  font-size:24px;
  font-weight:900;
  line-height:1.25;
  color:white;
  @media (min-width: 992px) {
    font-size:25px;
  }
  @media (min-width: 1200px) {
    font-size:27px;
  }
  @media (min-width: 1600px) {
    font-size:30px;
  }
}
@mixin font_H4 {
  font-family: $secondary-font;
  font-size:24px;
  font-weight:700;
  line-height:1.25;
  color:white;
  @media (min-width: 992px) {
    font-size:22px;
  }
  @media (min-width: 1200px) {
    font-size:24px;
  }
  @media (min-width: 1600px) {
    font-size:24px;
  }
}

@mixin font_P1 {
  font-family: $primary-font;
  font-size:20px;
  font-weight:300;
  line-height:1.9;
  color:white;
}

@mixin font_P2 {
  font-family: $primary-font;
  font-size:22px;
  font-weight:300;
  line-height:2.2;
  color:white;
}

// BUTTONS

@mixin btn_line {
  font-family: $primary-font;
  display:inline;
  position:relative;
  cursor:pointer;
  &:before,
  &:after {
    content:"";
    position:absolute;
    height:4px;
    bottom:-8px;
    left:0;
    border-radius:3px;
  }
  &:before {
    width:100%;
    z-index:1;
  }
  &:after {
    width:0;
    z-index:2;
  }
  &:hover,
  &:focus {
    text-decoration:none;
    &:after {
      width:100% !important;
    }
  }
}

// GRADIENTS

@mixin gradient_top {
  top:0;
  background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );
}

@mixin gradient_bottom {
  bottom:0;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 );
}

// LIST

@mixin list_default {
  list-style:none;
  margin:0;
  padding:0;
  position:relative;
}

