@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,900&display=swap');
@import "partials/_variables";
@import "partials/_mixins";
@import "partials/_animations";
@import "partials/_buttons";
@import "partials/_global";
@import "partials/_nav";
@import "partials/_header";
@import "partials/_footer";

@import "layouts/home/_slider";
@import "layouts/home/_beers";
@import "layouts/home/_links";
@import "layouts/home/_newsletter";

@import "layouts/brewery/_overview";
@import "layouts/brewery/_team";

@import "layouts/beers/_item";
@import "layouts/beers/_overview";
@import "layouts/beers/_details";

@import "layouts/news/_overview";
@import "layouts/news/_item";

@import "layouts/contact/_forms";
@import "layouts/contact/_show";

@import "themes/_green";
