#beer-intro {
  margin-top:0;
  .container {
    padding:0;
    .wrapper {
      .images {
        border-radius:0;
      }
    }
    .list-details {
      padding:0 30px;
    }
  }
}

.beer-lovers {
  position:relative;
  width:100%;
  margin-top:50px;
  overflow:auto;
  opacity: 0;
  animation: titleFadeInUp .4s forwards ease;
  animation-delay: 1s;
    h3 {
      @include font_H3();
      margin-top:30px;
      margin-bottom:25px;
      display:block;
      width:100%;
      position:relative;
      &:after {
        content:"";
        position:absolute;
        top:19px;
        right:0;
        width:calc(100% - 180px);
        height:1px;
        background:white;
        display: block;
        opacity:.2;
      }
    }
  .cols {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    position:relative;
  }
  .col-half {
    position:relative;
    width:100%;
  }
  .bg-container {
    position:relative;
    background:rgba(255,255,255,.1);
    padding:25px 25px 32px 25px;
    border-radius:8px;
    margin-top:30px;
    width:100%;
    h4 {
      @include font_H4();
      font-size:22px;
      margin-bottom:20px;
      display:block;
    }
  }
  .bg-container {
    .unique-ingredients {
      @include list_default();
      display:flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      li {
        position:relative;
        padding:0;
        margin:0;
        text-transform:uppercase;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 100%;
        max-width: 100%;
        img {
          position:relative;
          max-width:60px;
          height:auto;
        }
        span {
          @include font_P1();
          line-height:1.5;
          text-transform: lowercase;
          margin:0;
          padding:0;
        }
      }
    }
    .ebu-range {
      position:relative;
      display:block;
      width:100%;
      height:28px;
      margin:34px 0 18px 0;
      background: #e6e5ea;
      background-image: linear-gradient(to right, #f0eebc 9%, #f7e385 18%, #dcb236 27%, #d5a435 36%, #c88b2d 45%, #bb7531 54%, #a7582e 63%, #682519 72%, #501113 81%, #311214 90%, #0e0506 99%);
      border-radius:14px;
      z-index:1;
      span {
        font-weight:400;
        font-size:18px;
        font-family: $primary-font;
        text-align:center;
        color:var(--main-color, $green);
        width:38px;
        height:38px;
        padding-top:2px;
        background:rgba(255,255,255,1);
        border-radius:50%;
        border:3px solid rgba(255,255,255,1);
        top:-5px;
        left:50%;
        position:absolute;
        z-index:2;
      }
    }
  }

  ul {
    @include list_default();
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    li {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex:100%;
      width:100%;
      max-width:100%;
      align-items:center;

      margin: 15px 0 0 0;
      justify-content: space-between;
      border-bottom: 1px solid
      rgba(255,255,255,.1);
      padding-bottom: 15px;
      .icon {
        width:75px;
        height:75px;
        background:var(--main-color, $green);
        border-radius:50%;
        display:flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: $primary-font;
          font-weight:900;
          font-size:28px;
          text-transform: uppercase;
        }
        img {
          width:55px;
          max-width:55px;
        }
      }
      .short {
        padding-left:15px;
        font-family: $primary-font;
        font-size:22px;
        max-width: calc(100% - 90px);
        text-align: right;
      }
    }
  }
  /*
  &.green {
    .bg-container {
      .ebu-range {
        span {
          color:$green;
        }
      }
    }
    ul {
      li {
        .icon {
          background:$green;
        }
      }
    }
  }
  &.red {
    ul {
      li {
        .icon {
          background:$red;
        }
      }
    }
  }
  &.orange {
    .bg-container {
      .ebu-range {
        span {
          color:$orange;
        }
      }
    }
    ul {
      li {
        .icon {
          background:$orange;
        }
      }
    }
  }
  &.mint {
    ul {
      li {
        .icon {
          background:$mint;
        }
      }
    }
  }
  &.taupe {
    ul {
      li {
        .icon {
          background:$taupe;
        }
      }
    }
  }

   */
}

.share {
  margin-top:50px;
  h3 {
    @include font_H3();
    margin-bottom:25px;
    position:relative;
    &:after {
      content:"";
      position:absolute;
      top:50%;
      right:0;
      transform:translateY(-50%);
      width:calc(100% - 445px);
      height:1px;
      background:white;
      display: inline-block;
      opacity:.2;
    }
  }
}
@media (min-width: 768px) {
  .beer-lovers {
    .col-half {
      width:50%;
      padding-right:5px;
    }
    .col-half + .col-half {
      padding-right: 0;
      padding-left: 5px;
    }
  }
}
@media (min-width: 992px) {
  #beer-intro {
    margin-top: 200px;
    .container {
      padding:0 15px;
      .wrapper {
        .images {
          border-radius:4px;
        }
      }
      .list-details {
        padding:0 60px 0 0;
      }
    }
  }
  .beer-lovers {
    .col-half {
      padding-right:25px;
    }
    .col-half + .col-half {
      padding-right: 0;
      padding-left: 25px;
    }
    .bg-container {
      .unique-ingredients {
        flex-direction: row;
        li {
          flex: 50%;
          max-width: 50%;
        }
      }
    }
    ul {
      li {
        flex:33.333333%;
        max-width:33.3333333%;
        margin:10px 0;
        padding-bottom:0;
        justify-content: flex-start;
        border-bottom:0;
        .short {
          text-align: left;
        }
      }
    }
  }
}
@media (min-width: 1200px) {}
@media (min-width: 1600px) {}