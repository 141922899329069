#home-links {
  width:100%;
  position:relative;
  overflow:auto;
}

.links-list {
  @include list_default();
  position:relative;
  display:block;
  width:100%;
  li {
    float:left;
    position:relative;
    width:100%;
    cursor:pointer;
    padding:10px 0;
    a {
      display:block;
      cursor:pointer;
      width: 100%;
      height: 100%;
      span.title {
        position:absolute;
        bottom:25px;
        left:25px;
        z-index:5;
        h3,
        h4 {
          margin:0;
          padding:0;
        }
        h3 {
          @include font_H2();
        }
        h4 {
          @include font_P1();
        }
      }
      span.image {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position:relative;
        z-index:0;
        img {
          max-width:100%;
          filter: grayscale(100%);
        }
        &:before {
          content:"";
          @include gradient_bottom();
          position:absolute;
          width:100%;
          height:250px;
          opacity:.85;
          z-index:2;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: $green;
          background: linear-gradient(110deg, $green 15%, #427f40 90%);
          mix-blend-mode: multiply;
          transition: opacity 0.3s ease-in-out;
        }
      }
      &:hover,
      &:focus {
        span.image {
          img {
            transition: transform 5s linear;
            transform: rotate(-2deg) scale(1.2);
          }
          &:after {
            opacity:1;
          }
        }
      }
    }
    span.title {
      position:absolute;
    }
    &:first-child {
      width:100%;
      min-height:250px;
      a {
        span.title {
          h3 {
            @include font_H2();
          }
        }
      }
    }
    &:nth-child(1) {
      a {
        span.image {
          &:after {
            background: $green;
            background: linear-gradient(110deg, $green 15%, #427f40 90%);
          }
        }
      }
    }
    &:nth-child(2) {
      a {
        span.image {
          &:after {
            background: $red;
            background: linear-gradient(110deg, $red 15%, #b91818 90%);
          }
        }
      }
    }
    &:nth-child(3) {
      a {
        span.image {
          &:after {
            background: $taupe;
            background: linear-gradient(110deg, $taupe 15%, #baa814 90%);
          }
        }
      }
    }
    &:nth-child(4) {
      a {
        span.image {
          &:after {
            background: $orange;
            background: linear-gradient(110deg, $orange 15%, #c78319 90%);
          }
        }
      }
    }
    &:nth-child(5) {
      a {
        span.image {
          &:after {
            background: $mint;
            background: linear-gradient(110deg, $mint 15%, #17908f 90%);
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .links-list {
    li {
      width: 50%;
      padding:5px;
      &:first-child {
        width:100%;
        min-height:150px;
        a {
          span.title {
            h3 {
              @include font_H2();
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .links-list {
    li {
      width:25%;
      padding:0;
      a {
        span.title {
          h3 {
            @include font_H3();
          }
          h4 {
            @include font_P1();
          }
        }
        span.image {
          img {
            transition: all 0.5s ease-in-out;
          }
        }
      }
      &:first-child {
        width:50%;
        min-height:465px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .links-list {
    li {
      &:first-child {
        min-height:555px;
      }
    }
  }
}
@media (min-width: 1600px) {
  .links-list {
    li {
      &:first-child {
        min-height:650px;
      }
    }
  }
}