header {
  width: 100%;
  height: 550px;
  position: relative;
  .title {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    h1,
    p {
      color: #fff;
      position: relative;
      animation-duration: .8s;
      animation-fill-mode: both;
      animation-name: titleFadeInUp;
    }
    h1 {
      @include font_H2();
      margin-top:30px;
      margin-bottom: 5px;
      animation-delay: .6s;
    }
    p {
      @include font_P1();
      text-transform: uppercase;
      animation-delay: .9s;
    }
  }
  .bg-image {
    position: relative;
    width:100%;
    height:100%;
    min-height:550px;
    opacity:.4;
  }
  #trigger {
    position: absolute;
    width: 1px;
    height: 1px;
    bottom:0;
    left:0;
  }
}