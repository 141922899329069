$primary-color:           #688D67;

// COLORS
$green:                   #688D67;
$red:                     #970101;
$orange:                  #F09B18;
$mint:                    #00aba9;
$taupe:                   #817300;
$primary-font-color:      #ffffff;

// FONTS
$primary-font:            'Source Sans Pro', sans-serif;
$secondary-font:          'Source Sans Pro', sans-serif;