.contact-info {
  display: block;
  width: 100%;
  margin-top: 30px;

  a {
    i {
      margin-right: 5px;
      font-size: 26px;
    }
  }
}

.follow {
  display: flex;
  flex-direction: row;
  flex-wrap:nowrap;
  align-items: center;
  align-content:center;
  width: 100%;
  background: black;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top:10px;
  margin-bottom:30px;
  border:1px solid rgba(255,255,255,.2);
  h4 {
    @include font_H4();
    line-height:1;
    margin:0 15px 0 0;
    padding:0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display:flex;

    li {
      float: left;
      margin: 0 5px 0 0;
      width: 40px;
      height: 40px;
      display: block;

      a {
        width: 40px;
        height: 40px;
        position: relative;
        background: white;
        border-radius: 50%;
        display: block;
        transition: transform .35s ease-in-out, background-color .5s ease-in-out;

        i {
          font-size:24px;
          color: black;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          transform: rotate(360deg);
          background: $green;
        }
      }

      &:nth-child(2) {
        a {
          &:hover {
            background: $red;
          }
        }
      }

      &:nth-child(3) {
        a {
          &:hover {
            background: $orange;
          }
        }
      }

      &:nth-child(4) {
        a {
          &:hover {
            background: $mint;
          }
        }
      }

      &:nth-child(5) {
        a {
          &:hover {
            background: $taupe;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .follow {
    border:0;
    margin-top:30px;
    margin-bottom:0;
  }
}