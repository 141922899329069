footer {
  width:100%;
  position:relative;
  padding-top:0px;
  margin-top:80px;
  background:black;
  &:before {
    content:"";
    position:absolute;
    top:-80px;
    left:0;
    height:80px;
    width:100%;
    background: -moz-linear-gradient(transparent, black); /* FF 3.6+ */
    background: -ms-linear-gradient(transparent, black); /* IE10 */
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%, #000000), color-stop(100%, #ffffff)); /* Safari 4+, Chrome 2+ */
    background: -webkit-linear-gradient(transparent, black); /* Safari 5.1+, Chrome 10+ */
    background: -o-linear-gradient(transparent, black); /* Opera 11.10 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr=transparent); /* IE6 & IE7 */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr=transparent)"; /* IE8+ */
    background: linear-gradient(transparent, black); /* the standard */
    z-index:0;
  }
  .footer-cols {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .footer-col {
      justify-content: space-between;
      flex-grow:1;
      h4 {
        @include font_H4();
        margin-top:35px;
        display:block;
      }
      .list-beers {
        @include list_default();
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        li {
          flex:50%;
          a {
            text-decoration: none;
          }
        }
      }
      .list-help {
        @include list_default();
        li {
          display:block;
          a {
            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
        }
      }
      .list-socials {
        @include list_default();
        margin-top:12px;
        overflow:hidden;
        li {
          float:left;
          margin:0 5px 0 0;
          width:32px;
          height:32px;
          display:block;
          a {
            width:32px;
            height:32px;
            position:relative;
            background:white;
            border-radius:50%;
            display:block;
            transition: transform .35s ease-in-out, background-color .5s ease-in-out;
            i {
              color:black;
              position:absolute;
              top:50%;
              left:50%;
              transform:translate(-50%,-50%);
            }
            &:hover {
              transform: rotate(360deg);
              background:$green;
            }
          }
          &:nth-child(2) {
            a {
              &:hover {
                background:$red;
              }
            }
          }
          &:nth-child(3) {
            a {
              &:hover {
                background:$orange;
              }
            }
          }
          &:nth-child(4) {
            a {
              &:hover {
                background:$mint;
              }
            }
          }
          &:nth-child(5) {
            a {
              &:hover {
                background:$taupe;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        max-width:50%;
        flex-grow: 1 !important;
      }
    }
  }
  > * {
    position:relative;
    z-index:1;
  }
  .bottom {
    text-align:center;
    width:100%;
    padding:30px 0 10px 0;
    p, a {
      @include font_P1();
      font-size:13px;
      margin:0;
    }
    a {
      color:#e10f21;
    }
  }
}
@media (min-width: 992px) {
  footer {
    padding-top:55px;
    margin-top:80px;
    background:black;
    .footer-cols {
      .footer-col {
        .list-beers {
          max-width:calc(100% - 10px);
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  footer {
    .footer-cols {
      .footer-col {
        .list-beers {
          max-width:calc(100% - 60px);
        }
      }
    }
  }
}
@media (min-width: 1600px) {
  footer {
    .footer-cols {
      .footer-col {
        .list-beers {
          max-width:calc(100% - 150px);
        }
      }
    }
  }
}