#home-newsletter {
  .words {
    display: inline;
    text-indent: 10px;
    span {
      position: absolute;
      opacity: 0;
      overflow: hidden;
      color:$primary-color;
      animation: rotateWord 12s linear infinite 0s;
      &:nth-child(2) {
        animation-delay: 4s;
      }
      &:nth-child(3) {
        animation-delay: 8s;
      }
    }
  }
}