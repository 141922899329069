.beer-highlight {
  margin-top: 30px;
  position: relative;
  width:100%;

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .short-text {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      opacity: 0;
      animation: titleFadeInUp .4s forwards ease;
      animation-delay: .3s;

      p, ul, ol, table, img {
        margin: 0 0 35px 0;
      }
    }
    .beer-item-new {
      width: 100%;
      max-width: 100%;
      position: relative;
      flex: 0 1 auto;
      border-radius: 4px;
      background: rgba(255,255,255,.05) !important;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      overflow: hidden;
      opacity: 0;
      animation: titleFadeInUp .5s forwards ease;
      animation-delay: .7s;

      .highlight-item {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        position: relative;
        cursor:pointer;

        .title {
          position: absolute;
          max-width: 80%;
          top: 35px;
          left: 35px;
          z-index: 6;

          h2 {
            @include font_H2();

            .badge-primary {
              background: $red;
            }
          }
        }

        .image {
          width: 100%;
          float: right;
          display: block;
          overflow: hidden;
          padding: 15px;
          border-radius: 4px;
          position:relative;
          z-index:5;

          img {
            float:right;
            position: relative;
            display: block;
            max-width: 100%;
            height: auto !important;
            border-radius: 4px;
            z-index: 1;
          }
        }

        .beer-details {
          display: block;
          position: relative;
          padding: 0 15px;

          h3 {
            @include font_H2();
            text-transform: uppercase;
            background: white;
            padding: 4px 15px;
            margin:15px 0;
            display: inline-block;
          }
        }

        .ingredients {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          position: relative;
          margin-bottom: 15px;

          .ingredient {
            @include list_default();
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin:0 0 15px 0;
            text-transform:uppercase;
            letter-spacing:1px;

            li {
              font-size: 20px;
              line-height: 1.4;

              .icon-container {
                width: 50px;
                height: 50px;
                display: block;

                img {
                  max-width: 100%;
                  display: block;
                }
              }

              &:first-child {
                margin-right: 5px;
              }
            }
          }
        }
        &:hover,
        &:focus {
          text-decoration:none;
        }
      }
    }

    &.green {
      h1 {
        color:$green;
      }
      .beer-item-new {

        .highlight-item {
          .title {
            h2 {
              .badge-primary {
                background: $orange;
              }
            }
          }

          .beer-details {
            h3 {
              color: $green;
            }
          }
        }
      }
    }

    &.red {
      h1 {
        color:$red;
      }
      .beer-item-new {

        .highlight-item {
          .title {
            h2 {
              .badge-primary {
                background: $orange;
              }
            }
          }

          .beer-details {
            h3 {
              color: $red;
            }
          }
        }
      }
    }

    &.orange {
      h1 {
        color:$orange;
      }
      .beer-item-new {

        .highlight-item {
          .title {
            h2 {
              .badge-primary {
                background: $red;
              }
            }
          }
          .beer-details {
            h3 {
              color: $orange;
            }
          }
        }
      }
    }

    &.mint {
      h1 {
        color:$mint;
      }
      .beer-item-new {

        .highlight-item {
          .title {
            h2 {
              .badge-primary {
                background: $red;
              }
            }
          }
          .beer-details {
            h3 {
              color: $mint;
            }
          }
        }
      }
    }

    &.taupe {
      h1 {
        color:$taupe;
      }
      .beer-item-new {

        .highlight-item {
          .title {
            h2 {
              .badge-primary {
                background: $red;
              }
            }
          }
          .beer-details {
            h3 {
              color: black;
            }
          }
        }
      }
    }
  }
}

.beers-overview {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  opacity: 0;
  animation: titleFadeInUp .4s forwards ease;
  animation-delay: 1s;

  h2 {
    margin:45px 0;
    position:relative;
  }

  .beer-item {
    margin-bottom:50px;
  }
}

@media (min-width: 992px) {
  .beer-highlight {
    margin-top: 200px;
    .wrapper {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      .short-text {
        width: 50%;
        padding-right: 20px;
      }
      .beer-item-new {
        width: 50%;
        min-height: 425px;
        max-height: 550px;
      }
    }
  }
  .beer-highlight {
    .wrapper {
      .beer-item-new {
        .highlight-item {
          transition:background ease-in-out 1s;
          .title {
            max-width: 45%;
            top: 50%;
            left: 50px;
            transform: translateY(-50%);
          }
          .image {
            padding:50px 50px 40px;
            img {
              max-width:75%;
            }
          }
          .beer-details {
            padding: 0 50px 50px 50px;
            h3 {
              margin:0;
            }
          }
          .ingredients {
            flex-direction: row;
            .ingredient {
              margin:0 60px 0 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .beer-highlight {
    .wrapper {
      .short-text {
        width: 40%;
      }
      .beer-item-new {
        width: 60%;
        min-height: 525px;
        max-height: 650px;
      }
    }
  }
}
@media (min-width: 1600px) {
  .beer-highlight {
    .wrapper {
      .short-text {
        width: 33.33333%;
        padding-right: 30px;
      }
      .beer-item-new {
        width: 60%;
        min-height: 625px;
        max-height: 750px;
      }
    }
  }
}