.news-intro {
  width:100%;
  position:relative;
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .image-block {
    width:100%;
    display:block;
  }
  .text-block {
    text-align:left;
    max-width:100%;
    margin:0 auto;
    overflow:hidden;
    opacity: 0;
    animation: titleFadeInUp .5s forwards ease;
    animation-delay: .35s;
    a, li, ol, p, table, td, tr, ul {
      @include font_P1();
    }
  }
  .images {
    margin:20px 0;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    opacity: 0;
    animation: titleFadeInUp .6s forwards ease;
    animation-delay: .7s;
  }
  .scrollme {
    position:relative;
    width:100%;
    .news-title {
      position:absolute;
      width:100%;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      text-align:center;
      z-index:2;
    }
    .news-image {
      position:relative;
      width:100%;
      height:auto;
      display:block;
      z-index:1;
    }
  }
}

.masonry-container {
  margin-top:60px;
}

@media (min-width: 992px) {
  .news-intro {
    margin-top:200px;
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}