#home-beers {
  width:100%;
  position:relative;
  overflow:auto;
  z-index:0;
  .container-beers {
    padding-right:0;
  }
  .text-block {
    padding-top:50px;
  }
  .beer-slider {
    width:100%;
    min-height: 275px;
    height:auto;
    .swiper-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow:hidden;
      .swiper-slide {
        width:80%;
        height:80%;
      }
    }
  }
  &:before {
    content:"";
    @include gradient_top();
    position:absolute;
    left:0;
    width:100%;
    height:100%;
    opacity:.8;
  }
}
@media (min-width: 992px) {
  #home-beers {
    .container-beers {
      padding-right:30px;
    }
    .text-block {
      padding-top:60px;
    }
  }
}
@media (min-width: 1200px) {
  #home-beers {
    .text-block {
      padding-top:80px;
    }
  }
}